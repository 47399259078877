<script lang="ts">
  import { page } from "$app/stores";

  export let title: string;
  export let description: string;
  export let imageUrl: string = "https://tupperbox.app/logo-512.png";
</script>

<svelte:head>
  <title>{title}</title>
  <meta name="description" content={description} />
  <meta property="og:title" content={title} />
  <meta property="og:type" content="website" />
  <meta property="og:url" content={$page.url.toString()} />
  <meta property="og:image" content={imageUrl} />
  <meta property="og:description" content={description} />
</svelte:head>
